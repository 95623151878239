import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    gql,
    defaultDataIdFromObject,
    NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { onError } from "@apollo/client/link/error";
import { auth } from "lib/firebase/client";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import * as Sentry from "@sentry/nextjs";
import { sha256 } from "crypto-hash";

const GET_CART_ITEMS = gql`
    query getCartItems {
        cartItems @client {
            uid
            tenantId
        }
    }
`;

export const cache = new InMemoryCache({
    // Apollo looks for `id`, and `_id` in responses when creating its normalised cache.
    // However, We use `uid` in most cases instead of `id` resulting in no caching.
    // This overrides the default behaviour for getting the cache key,
    // and fallsback to Apollo's behaviour if there is no `__typename` or `uid`.
    // Technically, this is deprecated in favour of `keyFields`, but there is
    // no other way of setting the default behaviour for all types.
    dataIdFromObject(res) {
        if (res?.__typename && res?.uid) {
            return `${res.__typename}:${JSON.stringify(res.uid)}`;
        }
        return defaultDataIdFromObject(res);
    },
    typePolicies: {
        Query: {
            fields: {
                cartItems: {
                    merge(_, incoming = []) {
                        return [...incoming];
                    },
                },
                bookings: relayStylePagination(["tenantId", "filter"]),
                settlements: relayStylePagination(["tenantId", "filters"]),
                competitions: relayStylePagination(["tenantId", "filter"]),
            },
        },
        Booking: {
            fields: {
                isInCart: {
                    read(_, { readField }) {
                        const uid = readField("uid");
                        const { cartItems } = cache.readQuery({
                            query: GET_CART_ITEMS,
                        }) as { cartItems?: { uid: string }[] };
                        return !!cartItems?.some((i) => i.uid === uid);
                    },
                },
            },
        },
    },
});

export const persistor =
    typeof window === "undefined"
        ? undefined
        : new CachePersistor({
              cache,
              storage: new LocalStorageWrapper(window.localStorage),
          });

export const createClient = (
    cache: InMemoryCache,
): ApolloClient<NormalizedCacheObject> => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map((error) => {
                // eslint-disable-next-line no-console
                console.error(
                    `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
                );
                Sentry.captureException(
                    new Error(`GraphQL error: ${error.message}`),
                );
            });
        }
        if (networkError) {
            // eslint-disable-next-line no-console
            console.log(`[Network error]: ${networkError}`);
        }
    });

    const apqLink = createPersistedQueryLink({ sha256 });

    const httpLink = new HttpLink({
        uri: process.env.NEXT_PUBLIC_ENJIN_KONSOL_PROKSI_API_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
        const { currentUser } = auth;
        const token = await currentUser?.getIdToken();
        if (!token) return { headers };
        return {
            headers: { ...headers, Authorization: `Firebase ${token}` },
        };
    });

    const link = ApolloLink.from([errorLink, authLink, apqLink, httpLink]);

    return new ApolloClient({ cache, link });
};
