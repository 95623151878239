import { Layout } from "antd";

// TODO: Deprecate this
const BlankLayout = ({ children }) => {
    return (
        <Layout>
            <Layout.Content>{children}</Layout.Content>
        </Layout>
    );
};

/**
 * We will move away from layouts, use this to avoid setting up
 * AntD's Layout component
 */
const BlankLayoutV2 = ({ children }) => {
    return children;
};

export default BlankLayout;
export { BlankLayoutV2 };
