import {
    useState,
    useEffect,
    useContext,
    createContext,
    ReactNode,
} from "react";
import {
    AuthError,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GoogleAuthProvider,
    linkWithPopup,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updatePassword,
    updateProfile,
    User,
    UserCredential,
} from "firebase/auth";
import { auth } from "lib/firebase/client";

type AuthContextData = {
    user: User | null;
    isCourtsite: boolean;
    isInitialised: boolean;
    signin: (email: string, password: string) => Promise<User>;
    signup: (email: string, password: string, name: string) => Promise<User>;
    signout: () => Promise<void>;
    sendPasswordResetEmail: (email: string) => Promise<void>;
    updatePassword: (password: string) => Promise<void>;
    initGoogleAuth: () => Promise<UserCredential>;
    initFacebookAuth: () => Promise<UserCredential>;
    linkGoogleAuth: () => Promise<void>;
    linkFacebookAuth: () => Promise<void>;
    hasPasswordAuth: boolean;
};

const uninitializedHandler = <T,>(): Promise<T> => {
    throw new Error("uninitialized");
};

const authContext = createContext<AuthContextData>({
    user: null,
    isCourtsite: false,
    isInitialised: false,
    signin: uninitializedHandler,
    signup: uninitializedHandler,
    signout: uninitializedHandler,
    sendPasswordResetEmail: uninitializedHandler,
    updatePassword: uninitializedHandler,
    initGoogleAuth: uninitializedHandler,
    initFacebookAuth: uninitializedHandler,
    linkGoogleAuth: uninitializedHandler,
    linkFacebookAuth: uninitializedHandler,
    hasPasswordAuth: false,
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const FirebaseAuthProvider = ({
    children,
}: {
    children?: ReactNode;
}): JSX.Element => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): AuthContextData => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
const useProvideAuth = (): AuthContextData => {
    const isServerSide = typeof window === "undefined";

    const [user, setUser] = useState<User | null>(auth.currentUser);
    const [isInitialised, setIsInitialised] = useState(false);

    const isCourtsite = !!user?.email?.endsWith("@courtsite.my");

    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signin = async (email: string, password: string): Promise<User> => {
        const resp = await signInWithEmailAndPassword(auth, email, password);
        return resp.user;
    };

    const signup = async (
        email: string,
        password: string,
        name: string,
    ): Promise<User> => {
        const resp = await createUserWithEmailAndPassword(
            auth,
            email,
            password,
        );
        const user = resp.user;
        setUser(user);
        await updateProfile(user, { displayName: name });
        return user;
    };

    const signout = async (): Promise<void> => {
        await signOut(auth);
        setUser(null);
    };

    const sendPassReset = async (email: string): Promise<void> => {
        await sendPasswordResetEmail(auth, email);
    };

    const updatePass = async (newPassword: string): Promise<void> => {
        if (!user) throw new Error("user not logged in");
        await updatePassword(user, newPassword);
    };

    const loginGAuth = (): Promise<UserCredential> => {
        const provider = new GoogleAuthProvider();
        provider.addScope("email");
        return signInWithPopup(auth, provider);
    };

    const loginFBAuth = (): Promise<UserCredential> => {
        const provider = new FacebookAuthProvider();
        provider.addScope("email");
        return signInWithPopup(auth, provider);
    };

    const linkGoogleAuth = async (): Promise<void> => {
        if (!user) throw new Error("user not logged in");
        const provider = new GoogleAuthProvider();
        await linkWithPopup(user, provider);
    };

    const linkFacebookAuth = async (): Promise<void> => {
        if (!user) throw new Error("user not logged in");
        const provider = new FacebookAuthProvider();
        await linkWithPopup(user, provider);
    };
    const hasPasswordAuth = !!user?.providerData.some(
        (pd) => pd.providerId === "password",
    );

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsInitialised(true);
        });
        // Cleanup subscription on unmount
        return () => unsubscribe();
    });

    return {
        user,
        isCourtsite,
        isInitialised: isServerSide ? true : isInitialised,
        signin,
        signup,
        signout,
        sendPasswordResetEmail: sendPassReset,
        updatePassword: updatePass,
        initGoogleAuth: loginGAuth,
        initFacebookAuth: loginFBAuth,
        linkGoogleAuth,
        linkFacebookAuth,
        hasPasswordAuth,
    };
};

export function isAuthError(err: Error): err is AuthError {
    return "code" in err && "message" in err;
}
